import React from 'react'
import './Banner.css'
import banner from '../../assets/img/banner/img-9.jpg'
import { Link } from 'react-router-dom'

export default function Banner() {
  return (
    <div className='banner'>
        <img src={banner} alt='banner' />
        <div className='cta'>
          <h1>We know <span>data</span>.</h1>
          <h1>We know <span>software</span>.</h1>
          <h1>We know <span>business</span>.</h1>
          <p>Creating solutions through understanding.</p>
          <Link to='/contact' className='btn-cta'>Schedule a call</Link>
        </div>
    </div>
  )
}
