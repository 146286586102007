import './Contact.css'
import ContactForm from './ContactForm'
// 

export default function Contact() {
  return (
    <div className='contact'>
      <div>
        <h1>Let's chat, it'd be great to meet you.</h1>
        <p>For general enquiries, please fill out the form to get in touch.</p>
      </div>
      <div className='contact-form'>
        <ContactForm />
      </div>
    </div>
  )
}
