import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Spinner from "../../components/Spinner/Spinner";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/config";

export default function QuoteForm() {
	const { register, handleSubmit } = useForm();
	const [submittedSuccessfully, setSubmitSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleQuoteSubmit = async (formData) => {
		setLoading(true);
		setError(false);
		try {
			await addDoc(collection(db, "quoteForm"), {
				...formData,
				createdAt: Math.floor(Date.now() / 1000),
			});
			setSubmitSuccess(true);
		} catch (err) {
			setError(err);
		}
		setLoading(false);
	};

	return (
		<form onSubmit={handleSubmit(handleQuoteSubmit)} className="quote-form">
			<FloatingLabel
				controlId="floatingInput"
				label="What's your name?"
				className="mb-3"
			>
				<Form.Control
					required
					type="name"
					placeholder="John Smith the Third"
					{...register("name")}
				/>
			</FloatingLabel>
			<FloatingLabel
				controlId="floatingInput"
				label="What email address can we reach you at?"
				className="mb-3"
			>
				<Form.Control
					required
					type="email"
					pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
					title="Please enter a valid email"
					placeholder="John Smith the Third"
					{...register("email")}
				/>
			</FloatingLabel>
			<FloatingLabel
				controlId="floatingInput"
				label="What's the name of your company?"
				className="mb-3"
			>
				<Form.Control
					required
					type="text"
					placeholder="John Smith the Third"
					{...register("companyName")}
				/>
			</FloatingLabel>
			<FloatingLabel
				controlId="floatingInput"
				label="What service do you require?"
				className="mb-3"
			>
				<div className="pill-holder">
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="Website"
							value="Website"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="Website">
							Website
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="eCommerce"
							value="eCommerce"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="eCommerce">
							eCommerce
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="data"
							value="data"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="data">
							Data analysis
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="BA"
							value="BA"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="BA">
							Business analysis
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="app"
							value="app"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="app">
							App development
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="talk"
							value="talk"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="talk">
							Someone to talk to
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="lost"
							value="lost"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="lost">
							I'm lost
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="cuddle"
							value="cuddle"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="cuddle">
							A cuddle
						</label>
					</div>
					<div className="pill">
						<input
							type="checkbox"
							className="btn-check"
							id="all"
							value="all"
							autocomplete="off"
							{...register("servicesNeeded")}
						/>
						<label className="btn-pill-check" for="all">
							All of it
						</label>
					</div>
				</div>
			</FloatingLabel>
			<p>
				By submitting this form I accept the{" "}
				<a href="/privacy-policy">Privacy Policy</a> of this site.
			</p>
			{loading ? (
				<Spinner />
			) : (
				submittedSuccessfully &&
				!error && (
					<div className="msg-success">
						<FontAwesomeIcon icon={faCheckCircle} />
						<p>
							Thank you for contacting us. We will be in touch as soon as
							possible.
						</p>
					</div>
				)
			)}
			{error && (
				<div className="msg-error">
					<FontAwesomeIcon icon={faXmarkCircle} />
					<p>Unable to submit your contact request. Please try again.</p>
				</div>
			)}
			{!submittedSuccessfully && (
				<button className="btn-simp" type="submit">
					Send message
				</button>
			)}
		</form>
	);
}
