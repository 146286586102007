import React from 'react'
import Banner from '../../components/Banner/Banner'
// import NoticeBoard from '../../components/NoticeBoard/NoticeBoard'
import AboutUs from './AboutUs'
import './Home.css'
// import OurClients from './OurClients'
import OurTech from './OurTech'
import OurWork from './OurWork'
import SignUp from './SignUp'
import TheTeam from './TheTeam'

export default function Home() {
  return (
    <div className='home'>
        <Banner />
        <AboutUs />
        <OurTech />
        <TheTeam />
        <SignUp />
        <OurWork />
        {/* <GetQuote /> */}
    </div>
  )
}
