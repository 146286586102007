import bootstrap from '../../assets/svg/bootstrap-4.svg'
import css3 from '../../assets/svg/css-3.svg'
import c from '../../assets/svg/c--4.svg'
import html from '../../assets/svg/html-1.svg'
import js from '../../assets/svg/javascript-1.svg'
import php from '../../assets/svg/php-logo-only-letter.svg'
import python from '../../assets/svg/python-5.svg'
import sass from '../../assets/svg/sass-1.svg'
import laravel from '../../assets/svg/laravel-2.svg'
import dotnet from '../../assets/svg/net-logo.png'
import fb from '../../assets/svg/firebase-1.svg'
import java from '../../assets/svg/java-4.svg'
import jquery from '../../assets/svg/jquery-2.svg'
import sql from '../../assets/svg/microsoft-sql-server-1.svg'
import mysql from '../../assets/svg/mysql-6.svg'
import postgres from '../../assets/svg/postgresql.svg'
import react from '../../assets/svg/react-2.svg'
import rnative from '../../assets/svg/react-native-1.svg'
import xamarin from '../../assets/svg/xamarin.svg'
import powerbi from '../../assets/svg/powerbi.png'
import tableau from '../../assets/svg/tableau.svg'

const TechLogos = [
    {
        id: 1,
        url:  html,
        alt: 'HTML'
    },
    {
        id: 2,
        url: css3 ,
        alt: 'CSS3'
    },
    {
        id: 3,
        url: php ,
        alt: 'PHP'
    },
    {
        id: 4,
        url: js ,
        alt: 'JavaScript'
    },
    {
        id: 5,
        url: bootstrap ,
        alt: 'Bootstrap'
    },
    {
        id: 6,
        url: c ,
        alt: 'C#'
    },
    {
        id: 7,
        url: python ,
        alt: 'Python'
    },
    {
        id: 8,
        url: sass ,
        alt: 'SASS'
    },
    {
        id: 9,
        url: laravel ,
        alt: 'Laravel'
    },
    {
        id: 10,
        url: dotnet ,
        alt: '.Net'
    },
    {
        id: 11,
        url: fb ,
        alt: 'Firebase'
    },
    {
        id: 12,
        url: java ,
        alt: 'Java'
    },
    {
        id: 13,
        url: jquery ,
        alt: 'jQuery'
    },
    {
        id: 14,
        url: sql ,
        alt: 'Microsoft SQL'
    },
    {
        id: 15,
        url: mysql ,
        alt: 'MySQL'
    },
    {
        id: 16,
        url: postgres ,
        alt: 'PostgreSQL'
    },
    {
        id: 17,
        url: react ,
        alt: 'ReactJS'
    },
    {
        id: 18,
        url: rnative ,
        alt: 'React Native'
    },
    {
        id: 19,
        url: xamarin ,
        alt: 'Xamarin'
    },
    {
        id: 20,
        url: powerbi ,
        alt: 'Power BI'
    },
    {
        id: 21,
        url: tableau ,
        alt: 'Tableau'
    },
    
]

export default TechLogos