import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import 'firebase/auth'
// import 'firebase/storage'

const firebaseConfig = {
	apiKey: "AIzaSyDIHymce6Hs0hadd1wz8b7KTO_ZFgHaJWw",
	authDomain: "bls-site-48604.firebaseapp.com",
	projectId: "bls-site-48604",
	storageBucket: "bls-site-48604.appspot.com",
	messagingSenderId: "635198532055",
	appId: "1:635198532055:web:8e3bcf1215e61f8195c392",
};

// init firebase
initializeApp(firebaseConfig);

// init firestore
const db = getFirestore();
// init auth
const auth = getAuth();

export { db, auth };
