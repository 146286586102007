import img from '../../assets/img/banner/box-2.jpg'
import { Link } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function SignUp() {
  return (
    <div className='home-box flex-box'>
        <div className='img-l'>
            <img src={img} alt='banner' />
        </div> 
        <div className='home-wrap'>
            <p>Get in touch</p>
            <h1>Contact us</h1> 
            <h2>For more general enquiries and more information.</h2>
            <Link to="/contact" className='btn-simp'>Learn more{/*<FontAwesomeIcon icon={faArrowRight} />*/}</Link>
            {/* <h3>Join our Newsletter</h3>
            <input placeholder='Email' /> */}
        </div>
    </div>
  )
}
