import React from 'react'
import { Link } from 'react-router-dom'

export default function GetQuote() {
  return (
    <div className='get-quote'>
      <div>
        <h1>You're still here?</h1>
        <p>Let's have a chat and discuss your next project.</p>
      </div>
      <div>
        <Link className='btn-primary' to="/quote">Get a quote</Link>
      </div>
    </div>
  )
}
