import React from 'react'
import './Quote.css'
import QuoteForm from './QuoteForm'

export default function Quote() {
  return (
    <div className='contact quote'>
      <div>
        <h1>Interested in working with us?</h1>
        <p>The more information you can give us, the more accurate the quotation will be.</p>
      </div>
      <div className='contact-form'>
        <QuoteForm />
      </div>
    </div>
  )
}
