import './Menu.css'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import MenuDropdown from './MenuDropdown'

export default function Menu() {
  return (
    <div className='menu-container'>        
        <NavLink to='/services'>
          What we do
          <FontAwesomeIcon icon={faChevronDown} />
          <MenuDropdown />
        </NavLink>
        <NavLink to='/process'>Our process</NavLink>
        <NavLink to='/about'>About us</NavLink>
        <NavLink to='/contact'>Contact us</NavLink>
    </div>
  )
}
