import './About.css'
import img from '../../assets/img/banner/img-17.jpg'
import Peeps from './Peeps'

export default function About() {
  return (
    <>
      <div className='about'>
        <div className='about-main'>
          <h1>Our Story. <span>(in a nutshell)</span></h1>
          <p>Experienced Web Designers, Developers, and Analysts with expertise in the digital sector who provide a personalized, and efficient service.</p>
          <p>Established in Cape Town in 2018, Bluelight Software has formed successful partnerships with clients and completed over 150 projects over the past four years. Over this time, the company has found its niche in working with businesses that require a personal, extremely fast, agile, and completely professional operating style.</p>
          <p>The team consists of a group of specialists who all get along fantastically and thrive as a unit. Day after day, week in and week out, each member continues to convert coffee and a gatsby into completed projects. Not only are they efficient, they are all quite attractive too.</p>
        </div>
        <div className='about-img'>
          <img src={img} alt='banner' />
        </div>
        <div className='about-stats'>
          <div>
            <h2>150+</h2>
            <p>Projects completed.</p>
          </div>
          <div>
            <h2>650+</h2>
            <p>Nervous breakdowns avoided.</p>
          </div>
          <div>
            <h2>884</h2>
            <p>Cups of coffee consumed.</p>
          </div>
          <div>
            <h2>265<span>&#8467;</span></h2>
            <p>Litres of coffee consumed.</p>
          </div>
        </div>
      </div>
      <Peeps />
    </>
  )
}
