import { Link } from 'react-router-dom'
import img from '../../assets/img/banner/box-3.png'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function AboutUs() {
  return (
    <div className='home-box flex-box'>
        <div  className='img-l'>
            <img src={img} alt='banner' />
        </div> 
        <div className='home-wrap'>
            <p>Here's a bit</p>
            <h1>About us</h1> 
            <h2>Leading software development designed to make your business more competitive.</h2>
            <Link to="/about" className='btn-simp'>Learn more{/*<FontAwesomeIcon icon={faArrowRight} /> */}</Link>
        </div>
    </div>
  )
}
