import { NavLink } from 'react-router-dom'

export default function MenuDropdown() {
  return (
    <div className='dropdown'>
        <p>Data Analysis
            <span>Make informed decisions with logical and systematic data analysis techniques.</span>
        </p>
        <p>Database Optimisation
            <span>Maximise the speed and efficiency with which your data is retrieved.</span>
        </p>
        <p>Web Design
            <span>Bespoke websites built for quality and speed, and geared to reach the largest possible audience.</span>
        </p>
        <p>eCommerce
            <span>Content that can be managed and updated easily while operating smoothly for online customers 24/7.</span>
        </p>
        <p>UI/UX Design
            <span>Fully customisable experience for users on web and mobile platforms.</span>
        </p>
        <p>SEO
            <span>Optimise your website's technical configuration, content relevance and link popularity.</span>
        </p>
        <p>Business Analysis
            <span>The perfect software solution for each and every business objective. Specialist teams allow for a completely dynamic approach.</span>
        </p>
        <p>Web Development
            <span>Create and maintain your site and its technical aspects for maximum design and performance.</span>
        </p>
        <p>App Development
            <span>Design and build applications of the highest quality.</span>
        </p>
        <p>Content Creation
            <span>Appeal to your audiences with written and visual content.</span>
        </p>
        <p>Graphic Design
            <span>Layout and production design for advertising and promotional media.</span>
        </p>
        <NavLink  to='/services'>
            See more..
        </NavLink>
    </div>
  )
}
