import React from 'react'
import peep2 from '../../assets/img/avatar/peep-2.svg'
import peep3 from '../../assets/img/avatar/peep-3.svg'
import peep5 from '../../assets/img/avatar/peep-5.svg'
import peep6 from '../../assets/img/avatar/peep-6.svg'
import peep8 from '../../assets/img/avatar/peep-8.svg'
import peep9 from '../../assets/img/avatar/peep-9.svg'

export default function Peeps() {
  return (
    <div className='about-peeps'>
          <h2>Take a peep at our peeps</h2>
          <div className='peeps-wrap'>
            <div className='peep'>
              <img src={peep9} alt='person' />
              <h3>Christopher van Blerk</h3>
              <h4>Head of Potatoes</h4>
              <span></span>
            </div>
            <div className='peep'>
              <img src={peep6} alt='person' />
              <h3>Christopher Shepherd</h3>
              <h4>Dream Alchemist</h4>
              <span></span>
            </div>
            <div className='peep'>
              <img src={peep3} alt='person' />
              <h3>Kirstie van Blerk</h3>
              <h4>Grand Master of Underlings</h4>
              <span></span>
            </div>
            <div className='peep'>
              <img src={peep5} alt='person' />
              <h3>Tertius Smit</h3>
              <h4>Wizard of Light Bulb Moments</h4>
              <span></span>
            </div>
            <div className='peep'>
              <img src={peep2} alt='person' />
              <h3>Hanno Vermeulen</h3>
              <h4>Problem Wrangler</h4>
              <span></span>
            </div>  
            <div className='peep'>
              <img src={peep8} alt='person' />
              <h3>The Introverts</h3>
              <h4>Who prefer not to be named</h4>
              <span></span>
            </div>
          </div>
        </div>
  )
}
