import './Process.css'
import img from '../../assets/img/img.png'

export default function Process() {
  return (
    <>
      <div className='process'>
        <div className='process-main'>
          <h1>How we get 'er done.</h1>
          <p>The Software Development Life Cycle process includes planning, designing, developing, testing and deploying with ongoing maintenance to create and manage applications efficiently.</p>
        </div>
        <div className='process-img right-img'>
        </div>
        <div className='process-img'>
        </div>
        <div className='process-main right'>
          <h1>Planning.</h1>
          <p>In this phase, our Project Manager will work closely with the development team by workshopping, discussing and creating a documented process plan for the project, as scoped by the client. This plan will consist of all the nitty-gritty details that nobody actually reads but must have on file, for example, the technical design, task list, resource plan, communications plan, budget, and initial schedule for the project. This plan assists in establishing the roles and responsibilities of the project team and its stakeholders.</p>
        </div>
        <div className='process-main'>
          <h1>Designing.</h1>
          <p>Now that the planning phase is complete and the client is happy with our approach, we can start designing a proof of concept based on the scope. This design concept and all facets thereof will be handled by our skilled architect and project owner. They will work tirelessly to develop the plans, schematics, database requirements and any other details regarding their project. This final design will be the roadmap by which we develop by.</p>
        </div>
        <div className='process-img right-img'>
        </div>
        <div className='process-img'>
        </div>
        <div className='process-main right'>
          <h1>Developing.</h1>
          <p>After the back-and-forth and the design has been signed off by the client we can start up the generators, kick the coffee machines to life and start the caffeine-fuelled coding marathon. This is where our team of tech wizards turn semi-colons and curly brackets display into a display of pretty things. This phase is when all hands-on-deck comes into play, we ensure development of the project runs as smoothly as possible while maintaining code integrity and mental fortitude.</p>
        </div>
        <div className='process-main'>
          <h1>Testing.</h1>
          <p>The final phase before letting of creations loose on the world is to test that it actually does what’s intended. This is where we become creative, we will do everything we can to break what we have just created. We set loose the horde of monkeys and mash buttons until we can prove there are no issues to be found. Once complete we finally rest and watch the sun rise on a grateful universe.</p>
        </div>
      </div>
      <div className='service-img full-row'>
        <img src={img} alt="service" />
      </div>
    </>
  )
}
