import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsSplitUpAndLeft, faHandSparkles, faRunning, faCoffee } from '@fortawesome/free-solid-svg-icons'

export default function OurClients() {
  return (
    <div className='home-box work-box parallax'>
        <h2>Our secret formula is</h2>
        <h1>Our process</h1>
        <div className='clients-wrap'>
            <div className='glass'><FontAwesomeIcon icon={faHandSparkles} /><h1>Clean design</h1></div>
            <div className='glass'><FontAwesomeIcon icon={faArrowsSplitUpAndLeft} /><h1>Agile approach</h1></div>
            <div className='glass'><FontAwesomeIcon icon={faRunning} /><h1>Speedy output</h1></div>
            <div className='glass'><FontAwesomeIcon icon={faCoffee} /><h1>Ample coffee</h1></div>
        </div>
    </div>
  )
}
