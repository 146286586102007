import logo from '../../assets/img/logo-long.png'
import { useNavigate } from 'react-router-dom'
import './Head.css'
import Menu from '../Menu/Menu';
// import MobileMenu from '../Menu/MobileMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass,faBars,faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react';
import { NavLink } from 'react-router-dom'
import '../Menu/MobileMenu.css'
import logoMobile from '../../assets/img/logo-long-light.png'
import { Link } from 'react-router-dom'


export default function Head() {
  let navigate = useNavigate();
  const goHome = () => {
    navigate('/home')
  }
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => {
    setSidebar(!sidebar)
  }

  return (
    <>
      <div className='head'>
        <div className='logo'>
          <img src={logo} alt='logo' onClick={goHome} />
        </div>
        <Menu />
        <div className='search'>
          <div>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
        </div>
        <div className='socials'>
          <div>
            <Link className='btn-pill' to="/quote">Get a quote</Link>
          </div>
        </div>
        <div className='mm'>
          <div>
            <FontAwesomeIcon icon={faBars} onClick={showSidebar} />
          </div>
        </div>
      </div>
      {/* <MobileMenu data={sidebar} onChange={data => setSidebar(data)} /> */}
      <div className={'mobilemenu mm-' + sidebar} >
        <div>
            <div className='mm-head'>
                <img src={logoMobile} alt='logo'/>
            </div>
            <div className='mm-menu'>
                <NavLink onClick={showSidebar} to='/'>Home</NavLink>
                <NavLink onClick={showSidebar} to='/process'>Our process</NavLink>
                <NavLink onClick={showSidebar} to='/services'>Our services</NavLink>
                <NavLink onClick={showSidebar} to='/about'>About us</NavLink>
                <NavLink onClick={showSidebar} to='/contact'>Contact us</NavLink>
            </div>
            <div className='mm-socials'>
              <div>
                {/* <a href="https://www.instagram.com/bluelightsoftware" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                <a href="https://www.instagram.com/bluelightsoftware" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a> */}
                <a href="https://www.instagram.com/bluelightsoftware" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
              </div>
            </div>
            <div className='mm-quote'>
              <NavLink onClick={showSidebar} className='btn-pill' to="/quote">Get a quote</NavLink>
            </div>
            <div className='close-mm'>
                <FontAwesomeIcon icon={faChevronUp} onClick={showSidebar}/>
            </div>
        </div>
      </div>
    </>
  )
}
