import './App.css';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom'
//pages
import Home from './pages/home/Home'
import Contact from './pages/contact/Contact'
import About from './pages/about/About'
import Services from './pages/services/Services'
import Process from './pages/process/Process'
//components
import Head from './components/Head/Head'
import Foot from './components/Foot/Foot'
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Quote from './pages/quote/Quote';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  return (
    
  <BrowserRouter>
    <div className="App">
      <Head />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/contact" element={ <Contact /> } />
        <Route path="/services" element={ <Services /> } />
        <Route path="/process" element={ <Process /> } />
        <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
        <Route path="/quote" element={ <Quote />} />
        <Route path="*" element={ <Navigate to="/" />} />
      </Routes>
      <Foot />
    </div>
  </BrowserRouter>
    
  );
}

export default App;
