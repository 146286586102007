import React from 'react'
import TechLogos from '../../components/Tech/TechLogos'

export default function OurTech() {
  return (
    <div className='home-box clients-box parallax'>
        <h2>Peek at our mad</h2>
        <h1>Tech skills</h1>
        <div className="bar">
          <span className="bar_content">
                <div className='tech-wrap'>
                    {/* <div className='tech'>dev</div> */}
                    {
                      TechLogos && TechLogos.map((logo) =>
                      <div key={logo.id} className='tech'>
                        <img src={logo.url} alt={logo.alt} className={`tech-logo ` + logo.alt} />
                        <span>{logo.alt}</span>
                      </div>
                      )
                    }
                </div>
          </span>
        </div>
    </div>
  )
}
