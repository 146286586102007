import './Services.css'
import s2 from '../../assets/img/services/2.png'
import s3 from '../../assets/img/services/3.png'
import s4 from '../../assets/img/services/4.png'
import s6 from '../../assets/img/services/6.png'
import s7 from '../../assets/img/services/7.png'
import s9 from '../../assets/img/services/9.png'
import s10 from '../../assets/img/services/10.png'
import s11 from '../../assets/img/services/11.png'
import s15 from '../../assets/img/services/15.png'
import s17 from '../../assets/img/services/17.png'
import s20 from '../../assets/img/services/20.png'

export default function Services() {
  return (
    <div className='services-container'>
      <h1>A team of expert misfits dedicated to</h1>
      <div className='services'>
        <div>
          <img src={s3} alt="placeholder" />
          <h2>Data Analysis</h2>
          <span>Understanding your business by interpreting, optimising and visualising your data in a easy to understand way.</span>
        </div>
        <div>
          <img src={s10} alt="placeholder" />
          <h2>Database Optimisation</h2>
          <span>Maximise the speed and efficiency with which your data is retrieved.</span>
        </div>
        <div>
          <img src={s11} alt="placeholder" />
          <h2>Web Design</h2>
          <span>Bespoke websites built for quality and speed, and geared to reach the largest possible audience.</span>
        </div>
        <div>
          <img src={s20} alt="placeholder" />
          <h2>eCommerce</h2>
          <span>Content that can be managed and updated easily while operating smoothly for online customers 24/7.</span>
        </div>
        <div>
          <img src={s15} alt="placeholder" />
          <h2>UI/UX Design</h2>
          <span>Fully customisable experience for users on web and mobile platforms.</span>
        </div>
        <div>
          <img src={s4} alt="placeholder" />
          <h2>SEO</h2>
          <span>Optimise your website's technical configuration, content relevance and link popularity.</span>
        </div>
        <div>
          <img src={s7} alt="placeholder" />
          <h2>Business Analysis</h2>
          <span>The perfect software solution for each and every business objective. Specialist teams allow for a completely dynamic approach.</span>
        </div>
        <div>
          <img src={s17} alt="placeholder" />
          <h2>Web Development</h2>
          <span>Create and maintain your site and its technical aspects for maximum design and performance.</span>
        </div>
        <div>
          <img src={s6} alt="placeholder" />
          <h2>App Development</h2>
          <span>Design and build applications guaranteed to impress.</span>
        </div>
        <div>
          <img src={s9} alt="placeholder" />
          <h2>Content Creation</h2>
          <span>Appeal to your audiences with written and visual content.</span>
        </div>
        <div>
          <img src={s2} alt="placeholder" />
          <h2>Graphic Design</h2>
          <span>Layout and production design for advertising and promotional media.</span>
        </div>
      </div>
    </div>
  )
}
