import React, { useRef, useState } from "react";
import { db } from "../../firebase/config";
import { collection, addDoc } from "firebase/firestore";

export const NewsletterInput = () => {
	const emailRef = useRef();
	const [hasSubscribed, setSubscribed] = useState(false);
	const [error, setError] = useState(null);

	const validateEmailAndSubscribe = (e) => {
		e.preventDefault();
		let input = emailRef.current.value.trim();
		if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)) {
			subscribeToNewsletter(input);
			setError(false);
		} else {
			setError(true);
		}
	};

	const subscribeToNewsletter = async (email) => {
		await addDoc(collection(db, "subscribers"), {
			email,
			createdAt: Math.floor(Date.now() / 1000),
		});
		setSubscribed(true);
	};

	return (
		<>
			{!hasSubscribed && (
				<div className="input-group">
					<input ref={emailRef} type="text" placeholder="email.." required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Please enter a valid email"/>
					<button
						className="btn-simp-active"
						onClick={validateEmailAndSubscribe}
					>
						Subscribe
					</button>
				</div>
			)}
			{hasSubscribed && (
				<div className="input-group">
					<input className="success-input" ref={emailRef} type="text" placeholder="Thank you." disabled />
					<button className="btn-simp-active success-btn" disabled>
						Great stuff
					</button>
				</div>
			)}
			{error && <div className="invalid">Oops, please use a valid email address.</div>}
		</>
	);
};
