import { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/config";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Spinner from "../../components/Spinner/Spinner";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

function ContactForm() {
	const { register, handleSubmit } = useForm();
	const [submittedSuccessfully, setSubmitSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const handleContactSubmit = async (formData) => {
		setLoading(true);
		setError(false);
		try {
			await addDoc(collection(db, "contactForm"), {
				...formData,
				createdAt: Math.floor(Date.now() / 1000),
			});
			setSubmitSuccess(true);
		} catch (err) {
			setError(err);
		}
		setLoading(false);
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(handleContactSubmit)}
				className="needs-validation"
			>
				<FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
					<Form.Control
						required
						type="name"
						placeholder="John Smith the Third"
						{...register("name")}
					/>
				</FloatingLabel>
				<FloatingLabel
					controlId="floatingInput"
					label="Email address"
					className="mb-3"
				>
					<Form.Control
						required
						type="email"
						pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
						title="Please enter a valid email"
						placeholder="name@example.com"
						{...register("email")}
					/>
				</FloatingLabel>
				<FloatingLabel
					controlId="floatingInput"
					label="Contact number"
					className="mb-3"
				>
					<Form.Control
						required
						type="contact"
						placeholder="(+27) 76 123 4567"
						{...register("contact")}
					/>
				</FloatingLabel>
				<FloatingLabel
					controlId="floatingTextarea"
					label="What can we do for you?"
					className="mb-3"
				>
					<Form.Control
						required
						as="textarea"
						placeholder="(+27) 76 123 4567"
						{...register("whatcanwedo")}
					/>
				</FloatingLabel>
				<FloatingLabel
					controlId="floatingTextarea"
					label="What challenges do you face?"
					className="mb-3"
				>
					<Form.Control
						as="textarea"
						placeholder="(+27) 76 123 4567"
						{...register("whatchallenges")}
					/>
				</FloatingLabel>
				<p>
					By submitting this form I accept the{" "}
					<a href="/privacy-policy">Privacy Policy</a> of this site.
				</p>
				{loading ? (
					<Spinner />
				) : (
					submittedSuccessfully &&
					!error && (
						<div className="msg-success slow-hide">
							<FontAwesomeIcon icon={faCheckCircle} />
							<p>
								Thank you for contacting us. We will be in touch as soon as
								possible.
							</p>
						</div>
					)
				)}
				{error && (
					<div className="msg-error slow-hide">
						<FontAwesomeIcon icon={faXmarkCircle} />
						<p>Unable to submit your contact request. Please try again.</p>
					</div>
				)}
				{!submittedSuccessfully && (
					<button className="btn-simp" type="submit">
						Send message
					</button>
				)}
			</form>
		</>
	);
}

export default ContactForm;
