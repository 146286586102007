import React from "react";
import "./Foot.css";
import logo from "../../assets/img/logo-dark.png";
import { Link } from "react-router-dom";
import { NewsletterInput } from "./NewsletterInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import GetQuote from '../Quote/GetQuote'
import { faArrowUp} from '@fortawesome/free-solid-svg-icons'

export default function Foot() {
	return (
		<>
			<button className='btn-pill scroll-to-top' onClick={() => document.getElementById('root').scrollIntoView({ behavior: 'smooth' })} ><FontAwesomeIcon icon={faArrowUp} /><span> Scroll to top</span></button>
			<GetQuote />
			<div className="foot">
				<div className="foot-hl">
					<Link to="/">Home</Link>
					<Link to="/process">Our process</Link>
					<Link to="/services">Our services</Link>
					<Link to="/about">About us</Link>
					<Link to="/contact">Contact us</Link>
					{/* <Link to="/">FAQ</Link> */}
					<Link to="/privacy-policy">Privacy Policy</Link>
					{/* <Link to="/">Sitemap</Link> */}
					<div className='socials'>
						<div>
							{/* <a href="https://www.instagram.com/bluelightsoftware" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
							<a href="https://www.instagram.com/bluelightsoftware" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a> */}
							<a href="https://www.instagram.com/bluelightsoftware" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
						</div>
					</div>
				</div>
				<div>
					<img src={logo} alt="logo" />
				</div>
				<div className="newsletter">
					<h2>Interested? Join our</h2>
					<h1>Newsletter</h1>
					<NewsletterInput />
				</div>
				<p>© Bluelight Software all rights reserved</p>
			</div>
		</>
	);
}
