import './PrivacyPolicy.css'
import img from '../../assets/img/img-2.png'

export default function PrivacyPolicy() {

  return (
    <>
      <div id='top' className='privacy-policy'>
          <div>
              <h1>Because we care about your privacy.</h1>
          </div>
          <div className='policy-wrap policy-img'>
            <img src={img} alt="service" />
          </div>
      </div>
      <div className='policy-body'>
        <span>
          <h2>Privacy Notice</h2>
          <h3>Last updated January 18, 2023</h3>
        </span>
        <span>
          <p>This privacy notice for Bluelight Software (‘Company’, ’we’, or ‘our’), describes how and why we might collect, store, use, and/or share (‘process’) your information when you use our services (‘Services’), such as when you:</p>
          <p>Visit our website at <a href='http://www.bluelightsoftware.co.za'>www.bluelightsoftware.co.za</a>, or any website of ours that links to this privacy notice.</p>
          <p>Engage with us in other related ways, including any sales, marketing, or events.</p>
          <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href = "mailto: chris@bluelightsoftware.co.za">chris@bluelightsoftware.co.za</a>.</p>
        </span>
        <span>
          <h2 id='tob'>Table of Contents</h2>
          <ol>
            <li onClick={() => document.getElementById('key').scrollIntoView({ behavior: 'smooth' })} >Summary of Key Points</li>
            <li onClick={() => document.getElementById('1').scrollIntoView({ behavior: 'smooth' })} >What Information do we collect?</li>
            <li onClick={() => document.getElementById('2').scrollIntoView({ behavior: 'smooth' })} >How do we process your information?</li>
            <li onClick={() => document.getElementById('3').scrollIntoView({ behavior: 'smooth' })} >What legal bases do we rely on to process your personal information?</li>
            <li onClick={() => document.getElementById('4').scrollIntoView({ behavior: 'smooth' })} >When and with whom do we share your personal information?</li>
            <li onClick={() => document.getElementById('5').scrollIntoView({ behavior: 'smooth' })} >How long do we keep your information?</li>
            <li onClick={() => document.getElementById('6').scrollIntoView({ behavior: 'smooth' })} >How do we keep your information safe?</li>
            <li onClick={() => document.getElementById('7').scrollIntoView({ behavior: 'smooth' })} >Do we collect information from minors?</li>
            <li onClick={() => document.getElementById('8').scrollIntoView({ behavior: 'smooth' })} >What are your privacy rights?</li> 
            <li onClick={() => document.getElementById('9').scrollIntoView({ behavior: 'smooth' })} >Controls for do-not-track features</li>
            <li onClick={() => document.getElementById('10').scrollIntoView({ behavior: 'smooth' })} >Do we make updates to this notice?</li>
            <li onClick={() => document.getElementById('11').scrollIntoView({ behavior: 'smooth' })} >How can you contact us about this notice?</li>
            <li onClick={() => document.getElementById('12').scrollIntoView({ behavior: 'smooth' })} >How can you review, update, or delete the data we collect from you?</li>
          </ol>
        </span>
        <span id='key'>
          <h2>Summary of Key Points</h2>
          <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also <span className='click-here' onClick={() => document.getElementById('tob').scrollIntoView({ behavior: 'smooth' })}>click here</span> to go directly to our table of contents. </p>
          <p>What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Bluelight Software and the Services, the choices you make, and the products and features you use. </p>
          <p>Do we process any sensitive personal information? We do not process sensitive personal information. </p>
          <p>Do we receive any information from third parties? We do not receive any information from third parties. </p>
          <p>How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</p>
          <p>In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties.</p>
          <p>How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
          <p>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</p>
          <p>How do you exercise your rights? The easiest way to exercise your rights is by filling out our data subject request form available here, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws. </p> 
        </span>
        <span id='1'>
          <h2><span>1.</span>What Information do we collect?</h2>
          <p>Personal information you disclose to us</p>
          <p><span>In Short:</span> We collect personal information that you provide to us. </p>
          <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. </p>
          <p>Personal information provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: </p>
          <ul>
            <li>Names</li>
            <li>Phone numbers</li>
            <li>Email addresses</li>
            <li>Mailing addresses</li>
            <li>Contact preferences</li>
          </ul>
          <p><span>Sensitive information.</span> We do not process sensitive information. </p>
          <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
        </span>
        <span id='2'>
          <h2><span>2.</span>How do we process your information?</h2>
          <p><span>In Short:</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. </p>
          <p>We process your personal information for a variety of reasons, depending on how to interact with our Services, including: </p>
          <ul>
            <li>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log into your account, as well as keep your account in working order.</li>
            <li>To save or protect an individual’s vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
          </ul> 
          <br />   
        </span>
        <span id='3'>
          <h2><span>3.</span>What legal bases do we rely on to process your personal information?</h2>
          <p><span>In Short:</span> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests. </p>
          <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in other to process your personal information. As such, we may rely on the following legal bases to process your personal information: </p>
          <ul>
            <li>Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Please email Bluelight Software at <a href = "mailto: chris@bluelightsoftware.co.za">chris@bluelightsoftware.co.za</a> with your enquiry.</li>
            <li>Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved. </li>
            <li>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person. </li>
          </ul>
          <br />
        </span>
        <span id='4'>
          <h2><span>4.</span>When and with whom do we share your personal information?</h2>
          <p><span>In Short:</span> We may share information in specific situations described in this section and/or with the following third parties. </p>
          <p>We may need to share your personal information in the following situations:</p> 
          <ul>
            <li>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
          </ul>
          <br />
        </span>
        <span id='5'>
          <h2><span>5.</span>How long do we keep your information?</h2>
          <p><span>In Short:</span> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law. </p>
          <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us. </p>
          <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. </p>
        </span>
        <span id='6'>
          <h2><span>6.</span>How do we keep your information safe?</h2>
          <p><span>In Short:</span> We aim to protect your personal information through a system or organisational and technical security measures. </p>
          <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services with a secure environment. </p>
        </span>
        <span id='7'>
          <h2><span>7.</span>Do we collect information from minors?</h2>
          <p><span>In Short:</span> We do not knowingly collect data from or market to children under 18 years of age. </p>
          <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href = "mailto: chris@bluelightsoftware.co.za">chris@bluelightsoftware.co.za</a>.</p>
        </span>
        <span id='8'>
          <h2><span>8.</span>What are your privacy rights?</h2>
          <p><span>In Short:</span> In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time. </p>
          <p>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section ‘HOW CAN YOU CONTACT US ABOUT THIS NOTICE?’ below. </p>
          <p>We will consider and act upon any request in accordance with applicable data protection laws. </p>
          <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details <a href='https://commission.europa.eu/index_en' target='_blank' rel="noreferrer">here</a>. </p>
          <p>If you are located in Switzerland, the contact details for the data protection authorities are available 
          <a href='https://www.edoeb.admin.ch/edoeb/en/home.html' target="_blank" rel="noreferrer">here</a>. </p>
          <p>Withdrawing your consent: If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section ‘HOW CAN YOU CONTACT US ABOUT THIS NOTICE?’ below. </p>
          <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. </p>
          <p>Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails we send, or by contacting us using the details provided in the section ‘HOW CAN YOU CONTACT US ABOUT THIS NOTICE?’ below. You will then be removed from the marketing lists. However, we may still communicate with you – for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. </p>
          <p><span>Account Information</span></p>
          <p>If you would at any time like to review or change the information in your account or terminate your account, you can: </p>
          <p>Contact us using the contact information provided. </p>
          <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we nay retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements. </p>
          <p>If you have questions or comments about your privacy rights, you may email us at <a href = "mailto: chris@bluelightsoftware.co.za">chris@bluelightsoftware.co.za</a>.</p>
        </span>
        <span id='9'>
          <h2><span>9.</span>Controls for do-not-track features</h2>
          <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (‘DNT’) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
        </span>
        <span id='10'>
          <h2><span>10.</span>Do we make updates to this notice?</h2>
          <p><span>In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
          <p>We may update this privacy policy notice from time to time. The updated version will be indicated by an updated ‘Revised’ date and the updated version will be effective as soon as it is accessible. If we make material changes to the privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
        </span>
        <span id='11'>
          <h2><span>11.</span>How can you contact us about this notice?</h2>
          <p>If you have any questions or comments about this notice, you may email us at <a href = "mailto: chris@bluelightsoftware.co.za">chris@bluelightsoftware.co.za</a> or by post to:</p>
          <ul>
            <li>Bluelight Software</li>
            <li>3 Hydro Road</li>
            <li>Stikland</li>
            <li>Cape Town, Western Cape, 7530</li>
            <li>South Africa</li>
          </ul>
        </span>
        <span id='12'>
          <h2><span>12.</span>How can you review, update, or delete the data we collect from you?</h2>
          <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please submit a request form by clicking here.</p>
        </span>
      </div>
    </>
  )
}
