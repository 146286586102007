import img from '../../assets/img/banner/box-1.png'
import { Link } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function TheTeam() {
  return (
    <div className='home-box flex-box'>
        <div className='home-wrap h-wrap-l'>
            <p>This is</p>
            <h1>Our team</h1> 
            <h2>The rumours are true. We are home to the brightest, coolest innovators, engineers, developers and Star Wars enthusiasts.</h2>
            <Link to="/about" className='btn-simp'>Learn more{/*<<FontAwesomeIcon icon={faArrowRight} />*/}</Link>           
        </div>
        <div className='img-r'>
            <img src={img} alt='banner' />
        </div>
    </div>
  )
}
